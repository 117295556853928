import * as React from "react"
// import { StoreProvider } from "./src/context/store-context"
import Account from "./src/wrappers/Account"

import netlifyIdentity from 'netlify-identity-widget'

netlifyIdentity.init() // Initialize Netlify Identity


// gatsby-browser.js
const logActivity = async (url) => {
  const user = netlifyIdentity.currentUser()

  if (user) {
    try {
      await fetch('/.netlify/functions/logActivity', {
        method: 'POST',
        body: JSON.stringify({ userId: user.id, userEmail: user.email, url }),
      })
    } catch (error) {
      console.error('Error logging activity:', error)
    }
  }
}



export const onRouteUpdate = ({ location }) => {
  logActivity(location.pathname)
}


export const wrapPageElement = ({ props: { pageContext }, element }) => (
	<Account pageContext={pageContext}>{element}</Account>
)
