// Example from https://developers.google.com/analytics/devguides/collection/gtagjs/events
// gtag('event', <action>, {
//   'event_category': <category>,
//   'event_label': <label>,
//   'value': <value>,
//   // To send a non-interaction event, set the non_interaction parameter to true:
//   'non_interaction': <true or false>
// });

const isBrowser = typeof window !== "undefined"
const GA_MEASUREMENT_ID = process.env.GATSBY_GA_MEASUREMENT_ID

function trackCustomEvent(action, data) {
	if (isBrowser) {
		window.gtag("event", action, { 
			...data,
			event_callback: function() {
				console.log(action, data)
			}
		})
	}
}

export function trackUser(id) {
	if (isBrowser && id && GA_MEASUREMENT_ID) {
		console.log(id)
		window.gtag('config', GA_MEASUREMENT_ID, {
			'user_id': id
		});
	}
}

export default trackCustomEvent