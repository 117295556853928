// Dependencies
import React, { useState, useContext, useEffect } from "react"
import { navigate } from "gatsby"
import GoTrue from 'gotrue-js';
import { useLocation } from "@reach/router"
import trackCustomEvent, { trackUser } from "../helpers/trackCustomEvent";

export const AccountContext = React.createContext()

export function useAccountContext() {
	const context = useContext(AccountContext)
	return context
}

const Account = ({ children }) => {

	const location = useLocation()

	// const [userHash, setHash] = useState(null)
	const [isLoggedIn, setLoggedIn] = useState(false)
	const [userRequestComplete, setUserRequestComplete] = useState(false)
	const [userData, setUserData] = useState({})

	let auth = new GoTrue({
	  APIUrl: `${process.env.GATSBY_HOST}/.netlify/identity`,
	  audience: '',
	  setCookie: false,
	});

  const getUser = () => {
		const user = auth.currentUser()
		const data = JSON.parse(JSON.stringify(user))
		if (data) {
			setLoggedIn(true)
			setUserData(data)
		} else {
			setLoggedIn(false)
		}
		setUserRequestComplete(true)
	}

	useEffect(() => {
    getUser()

		if (location.hash) {

			const hash = location.hash.substr(1).split('&').reduce((res, item) => {
				let parts = item.split('=')
				res[parts[0]] = parts[1]
				return res
			}, {})

			if (hash.confirmation_token) { // Account Confirmation Token
				auth
					.confirm(hash.confirmation_token, true)
					.then(response => {
						location.hash = ""
						setLoggedIn(true)
						// trackCustomEvent({
            //   category: "Signup",
            //   action: "Click",
            //   label: `${userData.email} sign-up confirmed`,
      			// })
						// typeof window !== "undefined" && window.gtag("event", "signup", { label: `${userData.email} sign-up confirmed` })
						trackCustomEvent("accounts_signup_confimation", {
							event_category: "accounts",
							event_label: `${userData.email} sign-up confirmed`,
						})

						navigate(`/courses`)
					})
					.catch(function(e) {
						console.log(`Failed :( Error: ${e}`)
					});
			} else if (hash.recovery_token) { // Account Recovery Token
				auth
				  .recover(hash.recovery_token)
				  .then(response => {
						// getUser()
						location.hash = ""
						setLoggedIn(true)
						navigate('/account?msg=Recovered')
				  })
				  .catch(error => console.log(error));
			} else if (hash.email_change_token) { // Email Change Token
				auth.currentUser()
					.update({ email_change_token: hash.email_change_token })
					.then(response => {
						location.hash = ""
						navigate('/account')
					})
					.catch(error => console.log(error))
			}
		}
  }, [])

	useEffect(() => {
		getUser()
	}, [isLoggedIn])

	useEffect(() => {
		if (userData.email) {
			trackUser(userData.email)
		}
	}, [userData.email])

	return (
		<AccountContext.Provider value={{ isLoggedIn, setLoggedIn, auth, userData, userRequestComplete }}>
			{children}
		</AccountContext.Provider>
	)
}

export default Account
