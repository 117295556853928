exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-activity-log-js": () => import("./../../../src/pages/activity-log.js" /* webpackChunkName: "component---src-pages-activity-log-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-delete-form-js": () => import("./../../../src/pages/deleteForm.js" /* webpackChunkName: "component---src-pages-delete-form-js" */),
  "component---src-pages-export-data-js": () => import("./../../../src/pages/export-data.js" /* webpackChunkName: "component---src-pages-export-data-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-pathways-js": () => import("./../../../src/pages/pathways.js" /* webpackChunkName: "component---src-pages-pathways-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-course-js": () => import("./../../../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */),
  "component---src-templates-media-js": () => import("./../../../src/templates/media.js" /* webpackChunkName: "component---src-templates-media-js" */),
  "component---src-templates-module-js": () => import("./../../../src/templates/module.js" /* webpackChunkName: "component---src-templates-module-js" */)
}

